import React, { useContext, useState, useMemo, useCallback } from 'react';
import styles from "./SearchSari.module.css";
import productContext from '../Context/ProductContext/ProductContext';
import { useNavigate } from 'react-router-dom';
import AllProduct from '../Body/AllProduct';

const SearchSari = () => {
    const navigate = useNavigate();
    const { products } = useContext(productContext);
    const auth = useContext(productContext);
    const url = auth.url2;
    const [date, setDate] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(true); 

    const sariData = useMemo(() => {
        setLoading(false);  
        return products.filter(product => product.date === date);
    }, [products, date]);

    const handleFocus = useCallback(() => setIsActive(true), []);
    const handleBlur = useCallback(() => setIsActive(false), []);
    const handleDateChange = useCallback((e) => setDate(e.target.value), []);

    const toDetails = useCallback((id) => {
        window.scrollTo(0, 0);
        navigate(`/productDetails/${id}`);
    }, [navigate]);

    return (
        <div className={styles.outerDiv}>
            <div className={styles.formDiv}>
                <label htmlFor="Date" className={styles.date}>
                    Search Sari By Date
                </label>
                <input
                    className={isActive ? styles.activeInput : styles.input}
                    type="date"
                    value={date}
                    onChange={handleDateChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            </div>

            {loading ? (
                // Skeleton Loader
                <div className={styles.skeletonContainer}>
                    {[...Array(6)].map((_, index) => (
                        <div key={index} className={styles.skeletonItem}></div>
                    ))}
                </div>
            ) : sariData.length > 0 ? (
                <div className={styles.container}>
                    {sariData.map((elem) => {
                        const { imgSrc, Price, title, _id } = elem;
                        return (
                            <div
                                onClick={() => toDetails(_id)}
                                key={_id}
                                className={styles.maindiv}
                            >
                                <div className={styles.image}>
                                    <img  src={`${url}/img/${imgSrc}`} alt={title} />
                                </div>
                                <div className={styles.details}>
                                    {title} - {Price}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <AllProduct />
            )}
        </div>
    );
};

export default SearchSari;
