import React, { useState, useRef } from "react";
import styles from "./ReviewsSection.module.css";
import { FaStar, FaStarHalfAlt, FaArrowLeft, FaArrowRight } from "react-icons/fa";

const ReviewsSection = () => {
  const [reviews, setReviews] = useState([
    {
      name: "Sudipta Mukherjee",
      image: "/IMG/R1.png",
      title: "Kanchipuram Silk Saree",
      review: "Darsh এর শাড়িতে আমি...ভীষণ সুন্দর শাড়িটা... থ্যাংক ইউ সাথী❤️",
      rating: 4.5,
    },              
    {
      name: "Santwana Ghosh Goon",
      image: "/IMG/R2.png",
      title: "Maharashtrian Saree",
      review: "On a wedding ceremony wearing a  beautiful collection  of Darsh saree.",
      rating: 4,
    },
    {
        name: "Dr Arundhati Chatterjee",
        image: "/IMG/R3.png",
        title: "Banarasi Silk Saree",
        review:"Darsh theke newa Gajji saree aj Christmas eve e amar Birthday te porlam",
        rating:4.5,
      },
      {
        name: "Shreetama Nayek",
        image: "/IMG/R4.png",
        title: "Bishnupuri Silk Saree",
        review: "এই বছর কলকাতার exhibition থেকে নেওয়া, বছর শেষে পরলাম। আবার অপেক্ষা তে আছি।।",
        rating: 5,
      },
      {
        name: "Arpita Chattaraj",
        image: "/IMG/R5.png",
        title: "Banarasi Silk Saree",
        review: "শাড়ি আর কিছুই না ; এককথায় বলা যায় সেটি হল লাবণ্যের ছয় গজ... ধন্যবাদ Darsh",
        rating: 4,
      },  
    {
        name: "Suchismita Ghosh",
        image: "/IMG/R6.png",
        title: "Bandhani saree",
        review: "বছর শুরু #darsh এর শাড়ি দিয়ে...",
        rating: 4.5,
    },              
    {
        name: "Arpita Saha",
        image: "/IMG/R7.png",
        title: "Patola",
        review: "Khub pochondo hoyeche saree ta .",
        rating: 4.5,
    },
    {
        name: "Paramita Bhowmik",
        image: "/IMG/R8.png",
        title: "Pure Silk Saree",
        review: "Darsh Lover❤️",
        rating: 5,
    },              
    {
        name: "Sriparna Dey",
        image: "/IMG/R9.png",
        title: "Lucknowi Chikankari",
        review: "Sathi thanks to you 💖💖💖💖💐",
        rating: 4.5,
    },
    {
      name: "Papiya Sharma",
      image: "/IMG/R10.png",
      title: "Silk Saree",
      review: "Anek pratiksha r saree  from DARSH",
      rating: 4.5,
   },
  ]);

  const scrollRef = useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;
    if (direction === "left") {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newReview, setNewReview] = useState({
    name: "",
    image: "",
    title: "",
    review: "",
    rating: 0,
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewReview({ ...newReview, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("New Review Data:", JSON.stringify(newReview, null, 2));  
    setReviews([...reviews, newReview]); 
    closeModal(); 
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;

    return (
      <div className={styles.stars}>
        {Array.from({ length: fullStars }).map((_, index) => (
          <FaStar key={index} />
        ))}
        {halfStar && <FaStarHalfAlt />}
      </div>
    );
  };

  return (
    <section className={styles.reviewsSection}>
       <div className={styles.header}>
        <h2>What Our Customers Are Saying</h2>
        
        <button className={styles.reviewButton} onClick={openModal}>
          Share Your Experience
        </button>
      </div>
       <div className={styles.subHeader}>
        <p> Hear from our happy customers! We’ve displayed 10 top reviews just for you.</p>
      </div>

 
      <div className={styles.scrollWrapper}>
        <button
          className={`${styles.scrollButton} ${styles.leftButton}`}
          onClick={() => scroll("left")}
        >
          <FaArrowLeft />
        </button>

        <div className={styles.reviewsContent} ref={scrollRef}>
          {reviews.map((review, index) => (
            <div key={index} className={styles.card}>
              {renderStars(review.rating)}
              <h4 className={styles.title}>{review.title}</h4>
              <p className={styles.review}>{review.review}</p>
              <div className={styles.userInfo}>
                <img
                  src={review.image || "https://via.placeholder.com/50"}
                  alt={review.name}
                  className={styles.userImage}
                />
                <p className={styles.userName}>{review.name}</p>
              </div>
            </div>
          ))}
        </div>
        <button
          className={`${styles.scrollButton} ${styles.rightButton}`}
          onClick={() => scroll("right")}
        >
          <FaArrowRight />
        </button>
      </div>

       {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Write a Review</h3>
            <form onSubmit={handleSubmit} className={styles.reviewForm}>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={newReview.name}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="image"
                placeholder="Image URL"
                value={newReview.image}
                onChange={handleChange}
              />
              <input
                type="text"
                name="title"
                placeholder="Review Title"
                value={newReview.title}
                onChange={handleChange}
                required
              />
              <textarea
                name="review"
                placeholder="Your Review"
                value={newReview.review}
                onChange={handleChange}
                required
              />
              <input
                type="number"
                name="rating"
                placeholder="Rating (0-5)"
                min="0"
                max="5"
                step="0.5"
                value={newReview.rating}
                onChange={handleChange}
                required
              />
              <div className={styles.formButtons}>
                <button type="button" onClick={closeModal}>
                  Cancel
                </button>
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </section>
  );
};

export default ReviewsSection;
