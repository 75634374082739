import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';
import styles from './FirstPage.module.css';

const sliderImages = [
  '/IMG/p4.jpg',
  '/IMG/p3.jpg',
  '/IMG/p6.jpg',
  '/IMG/p5.jpg',
  '/IMG/p1.jpg',
  '/IMG/p2.jpg',
 ];

const FirstPage = () => {
  return (
    <div className={styles.heroContainer}>
      <div className={styles.sliderContainer}>
        <Swiper
          effect="coverflow"
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView="auto"
          loop={true}
          coverflowEffect={{
            rotate: 20,
            stretch: 0,
            depth: 120,
            modifier: 1,
            slideShadows: false,
          }}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          navigation={false}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          className={styles.mySwiper}
        >
          {sliderImages.map((image, index) => (
            <SwiperSlide key={index} className={styles.swiperSlide}>
              <div className={styles.slideWrapper}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className={styles.slideImage}
                />
                <div className={styles.textSection}>
                  <h1 className={styles.title}>Feel the Beauty of Eternity</h1>
                  <p className={styles.subtitle}>
                  Your love for saree and for Darsh makes our collection more
                  Beautiful
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default FirstPage;
