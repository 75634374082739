import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./PaymentConfirmationPage.module.css";
import { FaCheckCircle, FaStar } from "react-icons/fa";
import { FaHouseChimney } from "react-icons/fa6";
import { AiOutlineFileDone } from "react-icons/ai";

const PaymentConfirmationPage = () => {
  const [animate, setAnimate] = useState(false);
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const { subtotal, taxRate, fees } = location.state || {};

  const taxAmount = subtotal * (taxRate / 100);
  const totalAmount = subtotal - 2 * taxAmount;

  useEffect(() => {
    setAnimate(true);
  }, []);

  const goToHome = () => {
    window.scrollTo(0, 0);
    navigate("/");
  };
  const viewOrders = () => {
    window.scrollTo(0, 0);
    navigate("/profile");
  };

  const handleRating = (rate) => {
    setRating(rate);
  };

  return (
    <div className={`${styles.outerDiv} ${animate ? styles.fadeIn : ""}`}>
      <div
        className={`${styles.confirmationCard} ${
          animate ? styles.slideIn : ""
        }`}
      >
        <FaCheckCircle
          className={`${styles.successIcon} ${animate ? styles.pulse : ""}`}
        />
        <h1 className={styles.title}>Order placed successfully!</h1>
        <p className={styles.description}>Thank you for your purchase.</p>
        <br />
        <p className={styles.description}>Your order is confirmed.</p>

        <div className={styles.orderSummary}>
          <div className={styles.row}>
            <span>Subtotal</span>
            <span>₹{totalAmount.toFixed(2)}</span>
          </div>
          <div className={styles.row}>
            <span>CGST ({taxRate}%)</span>
            <span>₹{taxAmount.toFixed(3)}</span>
          </div>
          <div className={styles.row}>
            <span>SGST ({taxRate}%)</span>
            <span>₹{taxAmount.toFixed(3)}</span>
          </div>
          <div className={styles.row}>
            <span>Fees</span>
            <span>₹{fees.toFixed(3)}</span>
          </div>
          <div className={`${styles.row} ${styles.totalRow}`}>
            <span>Total</span>
            <span className={styles.totalAmount}>₹{subtotal.toFixed(2)}</span>
          </div>
        </div>

        <div className={styles.ratingSection}>
          <p>How was your experience?</p>
          <div className={styles.stars}>
            {[1, 2, 3, 4, 5].map((star) => (
              <FaStar
                key={star}
                onClick={() => handleRating(star)}
                className={
                  star <= rating ? styles.starFilled : styles.starEmpty
                }
              />
            ))}
          </div>
        </div>

        <button
          className={`${styles.homeButton} ${
            animate ? styles.buttonHover : ""
          }`}
          onClick={goToHome}
        >
          <FaHouseChimney /> Go to Home
        </button>
        <button
          className={`${styles.homeButton} ${
            animate ? styles.buttonHover : ""
          }`}
          onClick={viewOrders}
        >
          <AiOutlineFileDone /> View Orders
        </button>
      </div>
    </div>
  );
};

export default PaymentConfirmationPage;
