import React, { useState } from "react";
import styles from "./WhyChooseUs.module.css";
import { FaStarOfLife } from "react-icons/fa";

const WhyChooseUs = () => {
  const [expanded, setExpanded] = useState(null);

  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className={styles.container}>
      <div className={styles.textSection}>
        <h2 className={styles.title}>Why Choose DARSH</h2>
        <p className={styles.subtitle}>
          At DARSH, we specialize in bringing the elegance of Bengali sarees to the world. Here's why we stand out:
        </p>
        <div className={styles.options}>
          <div className={styles.option} onClick={() => toggleExpand(1)}>
            <div className={styles.optionHeader}>
              <span className={styles.icon}>🌟</span>
              <h3>Authenticity & Heritage</h3>
              <span className={expanded === 1 ? styles.arrowExpanded : styles.arrow}>&#8595;</span>
            </div>
            {expanded === 1 && (
              <p className={`${styles.optionText} ${styles.expandedText}`}>
                Our sarees are crafted by skilled artisans, preserving the rich tradition of Bengali weaving and design.
              </p>
            )}
          </div>
          {[
            "Customizable Designs",
            "Quick Delivery",
            "Ethical Craftsmanship",
            "Lower Inventory Risks",
          ].map((text, idx) => (
            <div key={idx} className={styles.option} onClick={() => toggleExpand(idx + 2)}>
              <div className={styles.optionHeader}>
                <h3>{text}</h3>
                <span
                  className={expanded === idx + 2 ? styles.arrowExpanded : styles.arrow}
                >
                  &#8595;
                </span>
              </div>
              {expanded === idx + 2 && (
                <p className={`${styles.optionText} ${styles.expandedText}`}>
                  {idx === 0
                    ? "Design your unique sarees with our expert guidance to reflect your personal style."
                    : idx === 1
                    ? "We ensure your orders are delivered swiftly, maintaining quality and reliability."
                    : idx === 2
                    ? "Our sarees are made with a commitment to fair wages and sustainable practices."
                    : "We help you minimize inventory risks with flexible order quantities."}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.imageSection}>
        <div className={styles.imageWrapper}>
          <img
            src="https://ik.imagekit.io/darsh/DSC00411.jpg?updatedAt=1736753379932"
            alt="Beautiful Bengali Sarees"
            className={styles.image}
          />
          <span className={styles.overlayIcon}>
            <FaStarOfLife />
          </span>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
