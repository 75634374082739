import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";  // Import Link from React Router
import styles from "./SwiperSection.module.css"; // CSS Module for styling

const SwiperSection = ({ slides }) => {
  return (
    <div className={styles.swiperContainer}>
      <h2 className={styles.heading}>BLOG</h2>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation
        pagination={{ clickable: true }}
        loop
        centeredSlides
        spaceBetween={30}
        slidesPerView={"auto"}
        className={styles.swiper}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className={styles.slide}>
            <div className={styles.card}>
              <iframe
                className={styles.video}
                src={slide.videoUrl}
                title={`YouTube Video ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              {slide.content && <div className={styles.overlay}>{slide.content}</div>}
              {/* <Link to={slide.shopNowUrl} className={styles.shopButton}>
                Shop Now
              </Link> */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperSection;
