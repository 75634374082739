import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import styles from "./ReelsCarousel.module.css";

const reelsData = [
  {
    id: 1,
    video: "https://youtu.be/X-mP_p0XrEI?si=9ftLaipYYJaZQKwN",
    title: "Kolkata Exhibition,  Countdown start  8th & 9th Feb 2025 !!",
    likes: 120,
    shopLink: "https://example.com/shop/1",
  },
  {
    id: 2,
    video: "https://youtube.com/shorts/8eBMeI2ZxD4?si=THC77E9H93_p5nqZ",
    title: "Exhibition memory 🧿Thank you for your support 💙",
    likes: 200,
    shopLink: "https://example.com/shop/2",
  },
  {
    id: 3,
    video: "https://youtube.com/shorts/eWsEOPLLgc8?si=if-1KfSyPymrlQr1",
    title: "Bardhaman Exhibition 2024",
    likes: 120,
    shopLink: "https://example.com/shop/1",
  },
  {
    id: 4,
    video: "https://youtube.com/shorts/pNNMmZDSih8?si=Jz0CSMgInxTgInM4",
    title: "It's all about our Dispatch story 💝",
    likes: 85,
    shopLink: "https://example.com/shop/3",
  },
  {
    id: 5,
    video: "https://youtube.com/shorts/Rk5I3nF9Vto?si=kAiKRvWw2JMwa5jF",
    title: "Darsh Website Launch",
    likes: 150,
    shopLink: "https://example.com/shop/4",
  },
];

const ReelsView = () => {
  const [currentReel, setCurrentReel] = useState(0);
  const [muted, setMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [likes, setLikes] = useState(reelsData.map((reel) => reel.likes));
  const [likeClicked, setLikeClicked] = useState(false);
  const videoRef = useRef(null);

  const handleNext = () => {
    setCurrentReel((prev) => (prev + 1) % reelsData.length);
  };

  const handlePrev = () => {
    setCurrentReel((prev) =>
      prev === 0 ? reelsData.length - 1 : prev - 1
    );
  };

  const toggleMute = () => {
    setMuted(!muted);
  };

  const handleLike = () => {
    setLikes((prev) => {
      const updatedLikes = [...prev];
      updatedLikes[currentReel] += 1;
      return updatedLikes;
    });

    setLikeClicked(true);
    setTimeout(() => setLikeClicked(false), 500);
  };

//   const handleShare = () => {
//     const reel = reelsData[currentReel];
//     if (navigator.share) {
//       navigator.share({
//         title: reel.title,
//         url: reel.video,
//       }).catch((error) => console.error("Share failed:", error));
//     } else {
//       navigator.clipboard.writeText(reel.video);
//       alert("Link copied to clipboard!");
//     }
//   };

  const handleShop = () => {
    window.open(reelsData[currentReel].shopLink, "_blank");
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsPlaying(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [currentReel]);

  return (
    <div className={`${styles.carousel} ${styles.animateDisplay}`}>
    <h1 className={styles.heading}>TRENDING LOOKS TO WATCH</h1>
       <div className={styles.reelContainer} ref={videoRef}>

        {/* Video Player */}
        <ReactPlayer
          url={reelsData[currentReel].video}
          playing={isPlaying}
          muted={muted}
          loop
          width="100%"
          height="100%"
          className={styles.videoPlayer}
        />

        {/* Overlay Content */}
        <div className={styles.overlay}>
          <h2 className={styles.title}>{reelsData[currentReel].title}</h2>
          <div className={styles.controls}>
          <button
              onClick={handleLike}
              className={`${styles.likeButton} ${likeClicked ? styles.likeAnimation : ""}`}
            >
              ❤️ {likes[currentReel]}
            </button>
            <button onClick={toggleMute}>{muted ? "🔇" : "🔊"}</button>
            {/* <button onClick={handleShop} className={styles.shopButton}>
            🛒 Shop Now
            </button> */}
            {/* <button onClick={handleShare}>🔗 Share</button> */}
          </div>
        </div>

        {/* Navigation Buttons */}
        <button onClick={handlePrev} className={`${styles.arrow} ${styles.left}`}>
          &#8249;
        </button>
        <button onClick={handleNext} className={`${styles.arrow} ${styles.right}`}>
          &#8250;
        </button>
      </div>
    </div>
  );
};

export default ReelsView;
