import React, { useContext, useEffect, useState, useRef } from 'react';
import styles from "./ProductList.module.css";
import { useNavigate } from 'react-router-dom';
import productContext from '../Context/ProductContext/ProductContext';
import { IoFilter } from "react-icons/io5";
import { useParams } from 'react-router-dom';

const ProductCard = () => {
    const context = useContext(productContext);
    const [products, setProducts] = useState([]);
    const [originalProducts, setOriginalProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({ availability: '', price: '' });
    const [deskSelectedFilters, setDeskSelectedFilters] = useState({ sort: "" });

    const url = context.url2;
    const navigate = useNavigate();
    const productRefs = useRef([]);
    const { catagory } = useParams();

    const loadingFun = async () => {
        setLoading(true); // Show loader during data fetching
        const response = await context.fetchProducts();
        const data = response.data.product.filter((product) => product.category === catagory);  // Filter by category
        setProducts(data);
        setOriginalProducts(response.data.product);  // Store all products for filtering/sorting
        setLoading(false); // Hide loader once fetching is complete
    };

    useEffect(() => {
        loadingFun();
    }, [catagory]); // Trigger when 'catagory' changes

    const toDetails = (id) => {
        window.scrollTo(0, 0);
        navigate(`/productDetails/${id}`);
    };

    useEffect(() => {
        const observerOptions = { threshold: 0.1 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(styles.visible);
                    observer.unobserve(entry.target);
                }
            });
        }, observerOptions);

        const currentRefs = productRefs.current;
        currentRefs.forEach((ref) => ref && observer.observe(ref));

        return () => {
            currentRefs.forEach((ref) => ref && observer.unobserve(ref));
        };
    }, [products]);

    const applyFiltersAndSort = () => {
        let filteredProducts = [...originalProducts];

        // Apply category filter
        filteredProducts = filteredProducts.filter((product) => product.category === catagory);

        // Apply sorting desktop preference
        const sortPreference = deskSelectedFilters.sort || selectedFilters.sort;
        switch (sortPreference) {
            case "lowToHigh":
                filteredProducts.sort((a, b) => a.price - b.price);
                break;
            case "highToLow":
                filteredProducts.sort((a, b) => b.price - a.price);
                break;
            case "az":
                filteredProducts.sort((a, b) => a.title.localeCompare(b.title));
                break;
            case "za":
                filteredProducts.sort((a, b) => b.title.localeCompare(a.title));
                break;
            default:
                break;
        }

        setProducts(filteredProducts);
    };

    const applyFilters = () => {
        let filteredProducts = [...originalProducts];

        // Apply category filter
        filteredProducts = filteredProducts.filter((product) => product.category === catagory);

        // Apply availability filter
        if (selectedFilters.availability === 'inStock') {
            filteredProducts = filteredProducts.filter(product => product.Quantity > 0);
        } else if (selectedFilters.availability === 'outOfStock') {
            filteredProducts = filteredProducts.filter(product => product.Quantity === 0);
        }

        // Apply sorting by price/title/date
        if (selectedFilters.price === 'lowToHigh') {
            filteredProducts.sort((a, b) => a.price - b.price);
        } else if (selectedFilters.price === 'highToLow') {
            filteredProducts.sort((a, b) => b.price - a.price);
        } else if (selectedFilters.price === 'az') {
            filteredProducts.sort((a, b) => a.title.localeCompare(b.title));
        } else if (selectedFilters.price === 'za') {
            filteredProducts.sort((a, b) => b.title.localeCompare(a.title));
        } else if (selectedFilters.price === 'dateOldToNew') {
            filteredProducts.sort((a, b) => new Date(a.dateAdded || 0) - new Date(b.dateAdded || 0));
        } else if (selectedFilters.price === 'dateNewToOld') {
            filteredProducts.sort((a, b) => new Date(b.dateAdded || 0) - new Date(a.dateAdded || 0));
        }

        
        setProducts(filteredProducts);
        toggleOverlay();
    };

    const toggleOverlay = () => setIsOverlayVisible((prev) => !prev);

    const handleFilterChange = (e) => {
        setSelectedFilters({ ...selectedFilters, [e.target.name]: e.target.value });
    };

    const handleDeskFilterChange = (e) => {
        const { name, value } = e.target;
        setDeskSelectedFilters((prev) => ({ ...prev, [name]: value }));
    };

    const clearFilters = () => {
        setSelectedFilters({ availability: '', price: '' });
        setDeskSelectedFilters({ sort: "" });
        setProducts(originalProducts.filter((product) => product.category === catagory));  // Reset to category products
    };

    useEffect(() => {
        applyFiltersAndSort();
    }, [selectedFilters, deskSelectedFilters]);

    return (
        <div>
            {loading ? (
                <div className={styles.skeletonContainer}>
                    {Array.from({ length: 8 }).map((_, index) => (
                        <div key={index} className={styles.skeletonCard}>
                            <div className={styles.skeletonImage}></div>
                            <div className={styles.skeletonDetails}>
                                <div className={styles.skeletonTitle}></div>
                                <div className={styles.skeletonPrice}></div>
                                <div className={styles.skeletonBadge}></div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div>
                    <h1 className={styles.allSariHeading}>{catagory}</h1>

                    {/* Filter and Sort Section */}
                    <div className={styles.filterSortSection}>
                        <div className={styles.filter} onClick={toggleOverlay}>
                            <span role="img" aria-label="filter">
                                <IoFilter />
                            </span>{" "}
                            Filter
                        </div>

                        <div className={styles.sortd}>
                            <label htmlFor="sort">Sort by:</label>
                            <select
                                id="sort"
                                name="sort"
                                value={deskSelectedFilters.sort}
                                onChange={handleDeskFilterChange}
                            >
                                <option value="">Select</option>
                                <option value="az">Alphabetically, A-Z</option>
                                <option value="za">Alphabetically, Z-A</option>
                                <option value="lowToHigh">Price, Low to High</option>
                                <option value="highToLow">Price, High to Low</option>
                            </select>
                        </div>

                        <div className={styles.productCount}>
                            {products.length} products
                        </div>
                    </div>

                    {/* Filter Overlay */}
                    {isOverlayVisible && (
                        <div className={styles.overlay}>
                            <div className={styles.overlayContent}>
                                <button
                                    className={styles.closeButton}
                                    onClick={toggleOverlay}
                                >
                                    ✖
                                </button>
                                <h2>Filter and Sort</h2>

                                <div className={styles.filterSection}>
                                    <label>Availability:</label>
                                    <select
                                        name="availability"
                                        value={selectedFilters.availability}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="inStock">In Stock</option>
                                        <option value="outOfStock">Out of Stock</option>
                                    </select>
                                </div>

                                <div className={styles.filterSection}>
                                    <label>Sort by:</label>
                                    <select
                                        name="price"
                                        value={selectedFilters.price}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="lowToHigh">Price, low to high</option>
                                        <option value="highToLow">Price, high to low</option>
                                        <option value="az">Alphabetical, A-Z</option>
                                        <option value="za">Alphabetical, Z-A</option>
                                    </select>
                                </div>

                                <div className={styles.filterActions}>
                                    <button
                                        onClick={clearFilters}
                                        className={styles.clearButton}
                                    >
                                        Clear
                                    </button>
                                    <button
                                        onClick={applyFilters}
                                        className={styles.applyButton}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={styles.container}>
                        {products.map(
                            ({ imgSrc, price, title, _id, Quantity, PNumber }, index) => (
                                <div
                                    key={_id}
                                    onClick={() => toDetails(_id)}
                                    className={styles.maindiv}
                                    ref={(el) => (productRefs.current[index] = el)}
                                >
                                    <div className={styles.image}>
                                        {!Quantity && (
                                            <span className={styles.outOfStockBadge}>
                                                Out of Stock
                                            </span>
                                        )}
                                        <img src={`${url}/img/${imgSrc}`} alt={title} loading="lazy" />
                                    </div>
                                    <div className={styles.details}>
                                        {title}
                                        <br />
                                        <p>₹{price}</p>
                                        <br />
                                        {PNumber === 13 || PNumber === 9 || PNumber === 10 ? (
                                            <p className={styles.preBooking}>
                                                <span>Pre-Booking Available</span>
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductCard;
