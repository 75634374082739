import React, { useContext, useEffect, useState, useRef } from 'react';
import styles from "./Allsari.module.css";
import { useNavigate } from 'react-router-dom';
import productContext from '../Context/ProductContext/ProductContext';
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";


// Skeleton 
const SkeletonLoader = () => {
  return (
    <div className={styles.containerMain}>
      <h2 className={styles.allSariHeading}>TRENDY COLLECTION</h2>
      

      <div className={styles.skeletonContainer}>
        {Array.from({ length: 8 }).map((_, index) => (
          <div key={index} className={styles.skeletonCard}>
            <div className={styles.skeletonImage}></div>
            <div className={styles.skeletonTitle}></div>
            <div className={styles.skeletonPrice}></div>
          </div>
        ))}
        <div className={styles.skeletonReadMoreContainer}>
          <div className={styles.skeletonReadMoreButton}></div>
        </div>
      </div>
    </div>
  );
};


const Allsari = () => {
  // console.log("Allsari.js");

  const context = useContext(productContext);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleCount, setVisibleCount] = useState(8);
  const navigate = useNavigate();
  const productRefs = useRef([]);
  const url = context.url2;

  const loadingFun = async () => {
    if (products.length === 0) {
      // Only fetch if products are not available
      const response = await context.fetchProducts();
      // console.log(response.data.product);
      setProducts(response.data.product); // Correctly set the fetched products
      setLoading(false); // After fetching, stop loading
    }
  }

  useEffect(() => {
    loadingFun();
  }, []);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.visible);
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    const currentRefs = productRefs.current;

    currentRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [products]);

  const toDetails = (id) => {
    window.scrollTo(0, 0);
    navigate(`/productDetails/${id}`);
  };

  const showMoreItems = () => {
    window.scrollTo(0, 0);
    navigate(`/allProduct`);
  };

   return (
    <div>
      {loading ? (
        <SkeletonLoader />

      ) : (
        <div className={styles.containerMain}>
          <h2 className={styles.allSariHeading}>TRENDY COLLECTION</h2>

          <div className={styles.container}>
            {products.slice(0, visibleCount).map(
              ({ imgSrc, price, title, _id, Quantity, PNumber }, index) => (
                <div
                  key={_id}
                  onClick={() => toDetails(_id)}
                  className={styles.maindiv}
                  ref={(el) => (productRefs.current[index] = el)}
                >
                  <div className={styles.image}>
                    {!Quantity && (
                      <span className={styles.outOfStockBadge}>
                        Out of Stock
                      </span>
                    )}
                    <img 
                      src={`${url}/img/${imgSrc}`}
                     alt={title} loading="lazy" />
                  </div>
                  <div className={styles.details}>
                    {title}
                    <br />
                    <p>₹{price}</p>
                    <br />
                    {PNumber == 13 || PNumber == 9 || PNumber == 10 ? (
                      <p className={styles.preBooking}>
                        <span>Pre-Booking Available</span>
                      </p>
                    ) : null}
                  </div>
                </div>
              )
            )}
          </div>
          {visibleCount < products.length && (
            <div className={styles.readMoreContainer}>
              <button onClick={showMoreItems} className={styles.readMoreButton}>
                Show more <span>&nbsp;&nbsp;</span> <MdOutlineKeyboardDoubleArrowRight />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Allsari;
