import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import styles from './Header.module.css';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { FiShoppingBag } from 'react-icons/fi';
import { RxAvatar } from 'react-icons/rx';
import { HiOutlineMenuAlt1, HiOutlineX } from 'react-icons/hi';
import { IoSearch } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import productContext from '../Context/ProductContext/ProductContext';
import { MdKeyboardArrowDown } from "react-icons/md";


const Header = () => {
  const { authinticated } = useContext(productContext);
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSareeDropdownOpen, setSareeDropdownOpen] = useState(false);
  const [isFirstMonthOpen, setFirstMonthOpen] = useState(false);
  const [isSecondMonthOpen, setSecondMonthOpen] = useState(false);
  const [isSection1Open, setSection1Open] = useState(false);
  const [isSection2Open, setSection2Open] = useState(false);
  const [isSection3Open, setSection3Open] = useState(false);
  const [isSection4Open, setSection4Open] = useState(false);

  const sareeDropdownRef = useRef(null);

  const toggleMobileMenu = useCallback(() => setIsMobileMenuOpen(prev => !prev), []);
  const closeMobileMenu = useCallback(() => setIsMobileMenuOpen(false), []);

  const handleNavigation = useCallback(
    (path,catagory) => {
      window.scrollTo(0, 0);
      navigate(path);
      closeMobileMenu();
    },
    [navigate, closeMobileMenu]
  );

  
  const toggleDropdown = (dropdownSetter, currentState) => dropdownSetter(!currentState);

   useEffect(() => {
    const handleClickOutside = (event) => {
      if (sareeDropdownRef.current && !sareeDropdownRef.current.contains(event.target)) {
        setSareeDropdownOpen(false);
        setFirstMonthOpen(false);
        setSecondMonthOpen(false);
        setSection1Open(false);
        setSection2Open(false);
        setSection3Open(false);
        setSection4Open(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const socialLinks = [
    { href: 'https://www.facebook.com/Darshpage', icon: <FaFacebook />, tooltip: 'Our Facebook' },
    { href: 'https://www.instagram.com/darsh_bysathi/', icon: <FaInstagram />, tooltip: 'Our Instagram' },
    { href: 'https://wa.link/wdfj7f', icon: <FaWhatsapp />, tooltip: 'Our Whatsapp' },
  ];

  return (
    <header className={styles.header}>
      {/* Upper Marquee */}
      <div className={styles.upHeaderUpper}>
        <marquee className={styles.marquee} >
          Welcome to Darsh - The Best Online Saree Store
        </marquee>
      </div>

      {/* Navigation Section */}
      <div className={styles.upHeader}>
        <nav className={styles.inUpheader}>
          <ul className={`${styles.listUpheaderLeft} ${isMobileMenuOpen ? styles.open : ''}`}>
            <li><button className={styles.navButton} onClick={() => handleNavigation('/')}>Home</button></li>
            <li><button className={styles.navButton} onClick={() => handleNavigation('/profile')}>My Account</button></li>
            <li><button className={styles.navButton} onClick={() => handleNavigation('/HotSells')}>Hot Sells</button></li>
            
            <li className={styles.dropdownItem} ref={sareeDropdownRef}>
              <button
               className={styles.navButton1}
               onClick={() => toggleDropdown(setSareeDropdownOpen, isSareeDropdownOpen)} >
                Our Sarees
                <span className={`${styles.dropdownIcon} ${isSareeDropdownOpen ? styles.open : ''}`}><MdKeyboardArrowDown /></span>
              </button>
              {isSareeDropdownOpen && (
               <ul className={`${styles.dropdownMenu} ${isSareeDropdownOpen ? styles.show : ''}`}>
                 <li><button onClick={() => { handleNavigation('/dropdown/product-list/Saraswati Puja Special'); setSareeDropdownOpen(false); }}>Saraswati Puja Special</button> </li>
                 <li><button onClick={() => { handleNavigation('/dropdown/product-list/Kanthastitch'); setSareeDropdownOpen(false); }}>Kanthastitch</button> </li>
                 <li><button onClick={() => { handleNavigation('/dropdown/product-list/Pure Silk Replica'); setSareeDropdownOpen(false); }}>Pure Silk Replica</button> </li>
                 <li> <button onClick={() => { handleNavigation('/dropdown/product-list/Pure Silk'); setSareeDropdownOpen(false); }}>Pure Silk</button> </li>
                 <li> <button onClick={() => { handleNavigation('/dropdown/product-list/Fashion Saree'); setSareeDropdownOpen(false); }}>Fancy Saree</button> </li>
                 {/* First Month */}
                 <li className={styles.dropdownItem}>
                   <button className={styles.navButton2} onClick={() => toggleDropdown(setFirstMonthOpen, isFirstMonthOpen)} >
                     Month Jan
                     <span className={`${styles.dropdownIcon} ${isFirstMonthOpen ? styles.open : ''}`}><MdKeyboardArrowDown /></span>
                   </button>
                   {isFirstMonthOpen && (
                      <ul className={`${styles.nestedDropdownMenu} ${isFirstMonthOpen ? styles.show : ''}`}>
                        <li><button onClick={() => { handleNavigation('/sarees/silk'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Silk Sarees</button> </li>
                        <li><button onClick={() => { handleNavigation('/sarees/cotton'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Cotton Sarees</button> </li>
                        <li><button onClick={() => { handleNavigation('/sarees/designer'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Designer Sarees</button> </li>
                     </ul>
                   )}
                 </li>
                 {/* Second Month
                 <li className={styles.dropdownItem}>
                   <button className={styles.navButton2} onClick={() => toggleDropdown(setSecondMonthOpen, isSecondMonthOpen)} >
                     Month Nov
                     <span className={`${styles.dropdownIcon} ${isSecondMonthOpen ? styles.open : ''}`}><MdKeyboardArrowDown /></span>
                   </button>
                   {isSecondMonthOpen && (
                      <ul className={`${styles.nestedDropdownMenu} ${isSecondMonthOpen ? styles.show : ''}`}>
                        <li><button onClick={() => { handleNavigation('/sarees/silk'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Silk Sarees</button> </li>
                        <li><button onClick={() => { handleNavigation('/sarees/cotton'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Cotton Sarees</button> </li>
                        <li><button onClick={() => { handleNavigation('/sarees/designer'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Designer Sarees</button> </li>
                     </ul>
                   )}
                 </li> */}

                 {/* Section 1 */}
                 {/* <li className={styles.dropdownItem}>
                   <button className={styles.navButton2} onClick={() => toggleDropdown(setSection1Open, isSection1Open)} >
                     Dhakal Benarasi
                     <span className={`${styles.dropdownIcon} ${isSection1Open ? styles.open : ''}`}><MdKeyboardArrowDown /></span>
                   </button>
                   {isSection1Open && (
                      <ul className={`${styles.nestedDropdownMenu} ${isSection1Open ? styles.show : ''}`}>
                        <li><button onClick={() => { handleNavigation('/sarees/silk'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Silk Sarees</button> </li>
                        <li><button onClick={() => { handleNavigation('/sarees/cotton'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Cotton Sarees</button> </li>
                        <li><button onClick={() => { handleNavigation('/sarees/designer'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Designer Sarees</button> </li>
                     </ul>
                   )}
                 </li> */}
                  {/* Section 2 */}
                  {/* <li className={styles.dropdownItem}>
                   <button className={styles.navButton2} onClick={() => toggleDropdown(setSection2Open, isSection2Open)} >
                     Crepe Benarasi
                     <span className={`${styles.dropdownIcon} ${isSection2Open ? styles.open : ''}`}><MdKeyboardArrowDown /></span>
                   </button>
                   {isSection2Open && (
                      <ul className={`${styles.nestedDropdownMenu} ${isSection2Open ? styles.show : ''}`}>
                        <li><button onClick={() => { handleNavigation('/sarees/silk'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Silk Sarees</button> </li>
                        <li><button onClick={() => { handleNavigation('/sarees/cotton'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Cotton Sarees</button> </li>
                        <li><button onClick={() => { handleNavigation('/sarees/designer'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Designer Sarees</button> </li>
                     </ul>
                   )}
                 </li> */}
                 {/* Section 3 */}
                 {/* <li className={styles.dropdownItem}>
                   <button className={styles.navButton2} onClick={() => toggleDropdown(setSection3Open, isSection3Open)} >
                     Benaras Kora
                     <span className={`${styles.dropdownIcon} ${isSection3Open ? styles.open : ''}`}><MdKeyboardArrowDown /></span>
                   </button>
                   {isSection3Open && (
                      <ul className={`${styles.nestedDropdownMenu} ${isSection3Open ? styles.show : ''}`}>
                        <li><button onClick={() => { handleNavigation('/sarees/silk'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Silk Sarees</button> </li>
                        <li><button onClick={() => { handleNavigation('/sarees/cotton'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Cotton Sarees</button> </li>
                        <li><button onClick={() => { handleNavigation('/sarees/designer'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Designer Sarees</button> </li>
                     </ul>
                   )}
                 </li> */}
                  {/* Section 4 */}
                  {/* <li className={styles.dropdownItem}>
                   <button className={styles.navButton2} onClick={() => toggleDropdown(setSection4Open, isSection4Open)} >
                     Month Nov
                     <span className={`${styles.dropdownIcon} ${isSection4Open ? styles.open : ''}`}><MdKeyboardArrowDown /></span>
                   </button>
                   {isSection4Open && (
                      <ul className={`${styles.nestedDropdownMenu} ${isSection4Open ? styles.show : ''}`}>
                        <li><button onClick={() => { handleNavigation('/sarees/silk'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Silk Sarees</button> </li>
                        <li><button onClick={() => { handleNavigation('/sarees/cotton'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Cotton Sarees</button> </li>
                        <li><button onClick={() => { handleNavigation('/sarees/designer'); setFirstMonthOpen(false); setSareeDropdownOpen(false); }}>Designer Sarees</button> </li>
                     </ul>
                   )}
                 </li> */}
                 <li> <button onClick={() => { handleNavigation('/sarees/cotton'); setSareeDropdownOpen(false); }}>Cotton Sarees</button> </li>
                 <li> <button onClick={() => { handleNavigation('/sarees/designer'); setSareeDropdownOpen(false); }}>Designer Sarees</button> </li>
               </ul>
             )}
            </li>

            <li><button className={styles.navButton} onClick={() => handleNavigation('/NewProduct')}>New Arrivals</button></li>
            <li><button className={styles.navButton} onClick={() => handleNavigation('/blogpage')}>Blog</button></li>
          </ul>

          <div className={`${styles.listUpheaderRight} ${isMobileMenuOpen ? styles.mobile : ''}`}>
            {socialLinks.map(({ href, icon, tooltip }, index) => (
              <div className={styles.iconWrapper} key={index}>
                <a href={href} target="_blank" rel="noreferrer">
                  {icon}
                </a>
                <span className={styles.tooltip}>{tooltip}</span>
              </div>
            ))}
          </div>
        </nav>
      </div>

      {/* Main Header */}
      <div className={styles.stick}>
        <div className={styles.mainHeader}>
          <div className={styles.inMainheader}>
            <div className={styles.logoDiv} onClick={() => handleNavigation('/')}>
              <img
                className={styles.logo}
                src="/IMG/Logo.jpg"
                alt="Darsh Logo"
              />
            </div>

            <div className={styles.profileAndCart}>
              <div className={styles.iconWrapper}>
                <IoSearch className={`${styles.search} ${styles.clickableIcon}`} onClick={() => handleNavigation('/SearchSari')} />
                <span className={styles.tooltip}>Search</span>
              </div>
              <div className={styles.iconWrapper}>
                <FiShoppingBag className={styles.clickableIcon} onClick={() => handleNavigation('/cart')} />
                <span className={styles.tooltip}>Add To Cart</span>
              </div>
              <div className={styles.iconWrapper}>
                <RxAvatar className={styles.clickableIcon} onClick={() => handleNavigation(authinticated ? '/profile' : '/login')} />
                <span className={styles.tooltip}>My Account</span>
              </div>
              {isMobileMenuOpen ? (
                <HiOutlineX className={styles.menuicon} onClick={toggleMobileMenu} aria-label="Close Menu" />
              ) : (
                <HiOutlineMenuAlt1 className={styles.menuicon} onClick={toggleMobileMenu} aria-label="Open Menu" />
              )}
            </div>
          </div>
        </div>
      </div>

      {isMobileMenuOpen && <div className={styles.menuOverlay} onClick={toggleMobileMenu} />}
    </header>
  );
};

export default Header;
