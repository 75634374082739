import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import styles from "./ProductDetailsPage.module.css";
import { FaPlus, FaMinus, FaShippingFast, FaWhatsapp } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import productContext from "../Context/ProductContext/ProductContext";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import Allsari from "../Body/Allsari";
import { GiReturnArrow } from "react-icons/gi";
import { BsQrCodeScan } from "react-icons/bs";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import alertSound from "../assets//mixkit-select-click-1109.wav";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { FaShareAlt, FaFacebookF, FaTwitter,  FaEnvelope, FaLink } from "react-icons/fa";
import { WhatsappShareButton, FacebookShareButton, TwitterShareButton, EmailShareButton } from "react-share";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";


// const availableColors = [
//   { name: "Red", code: "#FF0000", img: "https://ik.imagekit.io/darsh/InShot_20250113_174909737.jpg?updatedAt=1736775784754" },
//   { name: "Blue", code: "#0000FF", img: "https://ik.imagekit.io/darsh/Screenshot%202025-01-13%20123008.png?updatedAt=1736751645343" },
//   { name: "Green", code: "#00FF00", img: "https://ik.imagekit.io/darsh/Screenshot%202025-01-13%20123535.png?updatedAt=1736752026728" },
//   { name: "Yellow", code: "#FFFF00", img: "https://ik.imagekit.io/darsh/Screenshot%202025-01-13%20123523.png?updatedAt=1736752026650" },
//   { name: "Yellow", code: "#FFFF01", img: "https://ik.imagekit.io/darsh/InShot_20250113_174349818.jpg?updatedAt=1736775636632" },
//   { name: "Yellow", code: "#FFFF02", img: "https://ik.imagekit.io/darsh/InShot_20250113_173955452.jpg?updatedAt=1736775350893" },
//   { name: "Yellow", code: "#FFFF03", img: "https://ik.imagekit.io/darsh/InShot_20250113_174909737.jpg?updatedAt=1736775784754" },
//   { name: "Yellow", code: "#FFFF04", img: "https://media.wired.com/photos/598e35fb99d76447c4eb1f28/master/pass/phonepicutres-TA.jpg" },
// ];
const ProductDetailsSkeleton = () => {
  return (
    <div className={styles.skeletonOuter}>
      {/* Skeleton for Product Details */}
      <div className={styles.skeletonContainer}>
        <div className={styles.imageSkeleton}></div>
        <div className={styles.detailsSkeleton}>
          <div className={styles.titleSkeleton}></div>
          <div className={styles.priceSkeleton}></div>
          <div className={styles.descriptionSkeleton}></div>
          <div className={styles.buttonSkeleton}></div>
        </div>
      </div>

      {/* Skeleton for Related Products */}
      <div className={styles.relatedSkeleton}>
        <h2 className={styles.relatedSkeletonTitle}>You may also like</h2>
        <div className={styles.relatedSkeletonItems}>
          <div className={styles.relatedSkeletonItem}></div>
          <div className={styles.relatedSkeletonItem}></div>
          <div className={styles.relatedSkeletonItem}></div>
          <div className={styles.relatedSkeletonItem}></div>
        </div>
      </div>
    </div>
  );
};

const ProductDetailsPage = () => {
  // console.log("ProductDetailsPage.js");

  // Share URL and Share Options
  const [shareURL, setShareURL] = useState("");
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");

  useEffect(() => {
    setShareURL(window.location.href);  
  }, []);

  const toggleShareOptions = () => {
    setShowShareOptions((prev) => !prev);
  };

  const handleOptionClick = () => {
    setShowShareOptions(false);  
  };

  const handleCopyURL = () => {
    navigator.clipboard.writeText(shareURL);
    setCopySuccess("URL Copied!");
    setTimeout(() => setCopySuccess(""), 2000); 
    handleOptionClick();
  };
  const [loading, setLoading] = useState(true);

  const audioRef = useRef(new Audio(alertSound));
  const [isRedirecting, setIsRedirecting] = useState(false);

  const auth = useContext(productContext);
  const token = auth.authinticated;
  const navigate = useNavigate();
  const cart = () => {
    window.scrollTo(0, 0);
    navigate("/cart");
  };

  const toDetails = (id) => {
    window.scrollTo(0, 0);
    navigate(`/productDetails/${id}`);
  };

  // console.log("token", token)
  const [quantity, setQuantity] = useState(1);
  const [productDetails, setProductDetails] = useState({
    title: "",
    description: "",
    price: 0,
    Quantity: 0,
    PNumber: 0,
    category: "",
    date: "",
    imgSrc: "",
    qty: 0,
  });
  const [cartDetails, setCartDetails] = useState({
    productId: "",
    title: "",
    price: 0,
    qty: 0,
    imgSrc: "",
  });

  const { id } = useParams();
  const Context = useContext(productContext);
  const url = Context.url;
  const url2 = Context.url2;
  // console.log(url)
  // Fetch product details
  const fetchProductDetails = useCallback(async () => {
    try {
      const response = await axios.get(`${url2}/api/product/${id}`);
      // console.log(response.data.product);
      const {
        title,
        description,
        price,
        Quantity,
        PNumber,
        category,
        date,
        imgSrc,
        imgSrc1,
        imgSrc2,
        imgSrc3,
      } = response.data.product;
      setProductDetails({
        title,
        description,
        price,
        Quantity,
        PNumber,
        category,
        date,
        imgSrc,
        imgSrc1,
        imgSrc2,
        imgSrc3,
      });
    } catch (error) {
      console.log(error.message);
      toast.error("Failed to fetch product details!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [id, url2]);

  useEffect(() => {
    const fetchDetails = async () => {
      await fetchProductDetails();
      setLoading(false);
    };
    fetchDetails();
  }, [fetchProductDetails]);
  // console.log(productDetails.price)

  const [calPrice, setCalPrice] = useState(0);
  const [selectedColor, setSelectedColor] = useState("");

  // Update the main image when the color is selected
  const handleColorSelection = (color) => {
    setSelectedColor(color._id); 
    navigate(`/productDetails/${color._id}`)
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

 

  const [isPlusActive, setIsPlusActive] = useState(true); // Track the active button
  // Function to increase quantity
  const increaseQty = () => {
    setQuantity((prevQty) => prevQty + 1);
    setIsPlusActive(true); // Set plus as the active button
  };

  // Function to decrease quantity
  const decreaseQty = () => {
    setQuantity((prevQty) => (prevQty > 1 ? prevQty - 1 : 1));
    setIsPlusActive(false); // Set minus as the active button
  };

  // Update cartDetails when productDetails or quantity changes
  useEffect(() => {
    setCalPrice(quantity * productDetails.price);
    const { title, imgSrc } = productDetails;
    setCartDetails({
      productId: id,
      title,
      price: calPrice,
      qty: quantity,
      imgSrc,
    });
  }, [productDetails, quantity, id, calPrice]);

  // console.log(cartDetails);

  // Send cartDetails to backend http://localhost:2000/api/cart/addToCart
  const sendCartDetails = async () => {
    try {
      if (!token) {
        toast.warn("Please log in to add items to the cart.", {
          position: "top-right",
          autoClose: 3000,
        });
        navigate("/login");
        return;
      }
      await axios.post(`${url}/api/cart/addToCart`, cartDetails, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Auth: token,
        },
      });

      audioRef.current.play();

      // console.log('Product added successfully, showing toast...');
      toast.success("Product added to cart successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setIsRedirecting(true); // Start redirecting
      setTimeout(() => {
        cart(); // Navigate to cart after a short delay
      }, 1500);
    } catch (error) {
      // Show error toast message
      console.error("Error adding product:", error);
      toast.error("Failed to add product to cart!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [mainImgSrc, setMainImgSrc] = useState("");

  // Add an array of distinct images
  const [imageGallery, setImageGallery] = useState([]);

  // Update the main image and gallery when productDetails change
  useEffect(() => {
    if (productDetails && productDetails.imgSrc) {
      const updatedGallery = [
        `${url2}/img/${productDetails.imgSrc}`,
        `${url2}/img/${productDetails.imgSrc1}`,
        `${url2}/img/${productDetails.imgSrc2}`,
        `${url2}/img/${productDetails.imgSrc3}`,
      ].filter((src) => src); // Ensure no undefined or empty values
  
      setMainImgSrc(updatedGallery[0]); // Set the first image as the main image
      setImageGallery(updatedGallery); // Update the gallery
    }
  }, [productDetails, url2]);
  
  const handleThumbnailClick = (img) => {
    setMainImgSrc(img); // Set the selected image as the main image
  };

  // get all related products
  // useEffect(() => {
  //     auth.fetchProducts(); // Fetch products only once when the component mounts
  // }, [auth]);

  const sariData = auth.products.filter(
    (product) => product.category === productDetails.category
  );
  const sameSari = auth.products.filter(
    (product) => product.PNumber === productDetails.PNumber
  );
  return (
    <div>
      <ToastContainer />
      <audio ref={audioRef} src={alertSound} />
      {loading ? (
        <ProductDetailsSkeleton />
      ) : (
        <div>
          <div className={styles.outer}>
            {/* img div */}
            <div className={styles.imageDiv}>
              {/* Main displayed image */}
              <div className={styles.mainImg}>
                <img
                  className={styles.displaiedImg}
                  src={mainImgSrc}
                  alt="Product"
                />
              </div>

              {/* Thumbnail images */}
              <div className={styles.subImgDiv}>
                {imageGallery.map((img, index) => (
                  <img
                    key={index}
                    onClick={() =>  handleThumbnailClick(img)} // Update the main image on click
                    className={styles.subImage}
                    src={img}
                    alt={`Thumbnail ${index + 1}`}
                  />
                ))}
              </div>
            </div>
            {/* details div */}
            <div className={styles.detailsDiv}>
              {/* title div */}
              <div className={styles.productNameDiv}>
                <h2 className={styles.productName}>{productDetails.title}</h2>
                {/* verified */}

                <img
                  src="/IMG/verified.png"
                  alt="verified"
                  className={styles.verifiedImg}
                />
                <div className={styles.shareContainer}>
                  {/* Main Share Icon */}
                  <button
                    onClick={toggleShareOptions}
                    className={`${styles.shareButton} ${
                      showShareOptions ? styles.active : ""
                    }`}
                  >
                    <FaShareAlt className={styles.shareIcon} />
                  </button>

                  {/* Dropdown with Sharing Options */}
                  {showShareOptions && (
                    <div className={styles.shareOptions}>
                      <WhatsappShareButton
                        url={shareURL}
                        title="Check out this product!"
                        onClick={handleOptionClick}
                        className={styles.shareOption}
                      >
                        <FaWhatsapp
                          className={`${styles.icon} ${styles.whatsapp}`}
                        />
                      </WhatsappShareButton>
                      <FacebookShareButton
                        url={shareURL}
                        quote="Check out this product!"
                        onClick={handleOptionClick}
                        className={styles.shareOption}
                      >
                        <FaFacebookF
                          className={`${styles.icon} ${styles.facebook}`}
                        />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={shareURL}
                        title="Check out this product!"
                        onClick={handleOptionClick}
                        className={styles.shareOption}
                      >
                        <FaTwitter
                          className={`${styles.icon} ${styles.twitter}`}
                        />
                      </TwitterShareButton>
                      <EmailShareButton
                        url={shareURL}
                        subject="Check out this product!"
                        body="I found this amazing product, and I think you might like it!"
                        onClick={handleOptionClick}
                        className={styles.shareOption}
                      >
                        <FaEnvelope
                          className={`${styles.icon} ${styles.email}`}
                        />
                      </EmailShareButton>
                      <button
                        onClick={handleCopyURL}
                        className={styles.shareOption}
                      >
                        <FaLink className={`${styles.icon} ${styles.copy}`} />
                      </button>
                    </div>
                  )}

                  {/* Copy Success Message */}
                  {copySuccess && (
                    <div className={styles.copySuccess}>{copySuccess}</div>
                  )}
                </div>
              </div>

              {/* price div */}
              <div className={styles.descriptionDiv}>
                <h2>Price : </h2>
                <p style={{ fontSize: "20px" }}>{productDetails.price}</p>
              </div>
              {/* description div */}
              <div className={styles.descriptionDiv}>
                <h2>Description : </h2>
                <p>{productDetails.description}</p>
              </div>
              {/* quantity div */}
              <div className={styles.quantityDiv}>
                <h2>Quantity :</h2>
                <div className={styles.incDecBtnDiv}>
                  <div
                    className={`${styles.button} ${
                      !isPlusActive ? styles.activeButton : ""
                    }`}
                    onClick={decreaseQty}
                  >
                    <FaMinus className={styles.plusminus} />
                  </div>
                  <div className={styles.button}>{quantity}</div>
                  <div
                    className={`${styles.button} ${
                      isPlusActive ? styles.activeButton : ""
                    }`}
                    onClick={increaseQty}
                  >
                    <FaPlus className={styles.plusminus} />
                  </div>
                </div>
              </div>
              {/* stock div */}
              <div className={styles.descriptionDiv}>
                {productDetails.PNumber == 13 ||
                productDetails.PNumber == 9 ||
                productDetails.PNumber == 10 ? (
                  <h2> PRE-BOOKING GOING ON WHATSAPP US FOR MORE DETAILS</h2>
                ) : (
                  <h2></h2>
                )}
                {/* <h2>Stock : </h2>
                  <p style={{ fontSize: "20px" }}>{productDetails.Quantity}</p> */}
              </div>
              {/* size div */}
              {/* <div className={styles.quantityDiv}>
                            <h2>Size :</h2>
                            <div className={styles.incDecBtnDiv}>
                                <div className={styles.button}>S</div>
                                <div className={styles.button}>M</div>
                                <div className={styles.button}>L</div>
                            </div>
              </div> */}

              {/* Color Selection Section */}
              <div className={styles.colorSelectionDiv}>
                <h2>Select Color :</h2>
                <Swiper
                  spaceBetween={2}
                  slidesPerView={4}
                  grabCursor={true}
                  className={styles.colorSwiper}
                >
                  {sameSari.map((color) => (
                    <SwiperSlide key={color._id}>
                      <div
                        className={`${styles.colorBox} ${
                          selectedColor === color._id
                            ? styles.selectedColor
                            : ""
                        }`}
                        onClick={() =>handleColorSelection(color)}
                        title={`Select ${color.name}`}
                      >
                        <img
                          src={`${url2}/img/${color.imgSrc}`}
                          alt={color.name}
                          className={styles.colorImage}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {/* {selectedColor && (
                  <div className={styles.selectedColorInfo}>
                    <p>
                      {
                        availableColors.find((c) => c.code === selectedColor)
                          ?.name
                      }
                    </p>
                  </div>
                )} */}
              </div>

              {/* Add to Cart Button */}
              <div className={styles.buttonContainer}>
                {/* go to cart */}
                <button className={styles.wishlistBtn} onClick={cart}>
                  <i className="fa fa-heart">
                    <MdOutlineShoppingCartCheckout />
                  </i>{" "}
                  <p>GO TO CART</p>
                </button>
                {/* Add to Bag Button */}
                <button
                  className={styles.addToBagBtn}
                  onClick={sendCartDetails}
                  disabled={productDetails.Quantity ? false : true}
                >
                  <i className="faicon">
                    <FaShoppingCart />
                  </i>
                  {productDetails.Quantity ? (
                    <p>ADD TO CART</p>
                  ) : (
                    <p>OUT OF STOCK</p>
                  )}
                </button>
              </div>
              {/* Loading Section Under Add to Cart */}
              {isRedirecting && (
                <div className={styles.redirectLoader}>
                  <BeatLoader
                    color={"#009578"}
                    loading={isRedirecting}
                    size={10}
                  />
                  <p>Redirecting to cart...</p>
                </div>
              )}
              {/* New Details Section under Add to Cart */}
              <div className={styles.detailsSection}>
                <div
                  className={styles.detailItem}
                  onClick={() => {
                    navigate("/ShippingAndDelivery");
                    window.scrollTo(0, 0);
                  }}
                >
                  <FaShippingFast className={styles.icon} />
                  <p>Get it delivered in 5-7 days</p>
                </div>
                <div
                  className={styles.detailItem}
                  onClick={() => {
                    navigate("/PaymentOptions");
                    window.scrollTo(0, 0);
                  }}
                >
                  <BsQrCodeScan className={styles.icon} />
                  <p>Online Payment only</p>
                </div>
                <div
                  className={styles.detailItem}
                  onClick={() => {
                    navigate("/CancellationandRefund");
                    window.scrollTo(0, 0);
                  }}
                >
                  <GiReturnArrow className={styles.icon} />
                  <p>Return/Exchange within 3 days</p>
                </div>
              </div>
              {/* Contact Us */}
              <div className={styles.contactSection}>
                <h3>Contact Us</h3>
                <p>Get in touch directly on WhatsApp!</p>

                <a
                  href="https://wa.link/wdfj7f"
                  target="_blank"
                  rel="noreferrer"
                  className="whatsappLink"
                >
                  <button className={styles.whatsappButton}>
                    <FaWhatsapp className={styles.whatsappIcon} />
                    START CHAT
                  </button>
                </a>
              </div>
            </div>
            {/* <div style={{ height: "1000px" }}></div> */}
          </div>

          {/* Existing Related Products Section */}
          <div className={styles.outerDiv}>
            <div className={styles.formDiv}>
              <h1 className={styles.date}>You may also like</h1>
            </div>
            {sariData.length > 0 ? (
              <div className={styles.container}>
                {sariData.map((elem) => {
                  const { imgSrc, title, _id } = elem;
                  return (
                    <div
                      onClick={() => {
                        toDetails(_id);
                      }}
                      key={_id}
                      className={styles.maindiv}
                    >
                      <div className={styles.image}>
                        <img src={`${url2}/img/${imgSrc}`} alt={title} />
                      </div>
                      <div className={styles.details}>{title}</div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Allsari />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetailsPage;
