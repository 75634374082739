import SwiperSection from './SwiperSection';



const BlogPage = () => {
  
    const slides = [
      {
        videoUrl: "https://www.youtube.com/embed/UvYmW7hHe18?si=GI69Cv8eSsazucb0&amp;start=32",
        shopNowUrl: "/productDetails/6710b189e160c89ae6df4b74",
      },
      {
        videoUrl: "https://www.youtube.com/embed/scO9ZNl3sbo?si=OzzAX0QQyXqzpsBB",
        shopNowUrl: "/productDetails/6710b189e160c89ae6df4b74",
      },
        {
          videoUrl: "https://www.youtube.com/embed/Wv6g1XxYlcY?si=Ng9Kc9cBLAH8_kp_&amp;start=32",
          shopNowUrl: "/productDetails/6710b10ae160c89ae6df4b4f",
        },
        
      ];

    return (
        <>
            <SwiperSection slides={slides} />;
        </>
    );
};

export default BlogPage;
