import React from "react";
import styles from "./TopCatagory.module.css";
import {useNavigate} from "react-router-dom";

const TopCatagory = () => {
  const navigate = useNavigate();
  const categories = [
    {
      id: 4,
      title: "Saraswati Puja Special",
      img: '/IMG/p8.png',
      link:"/dropdown/product-list/Saraswati Puja Special"
    },
    {
      id: 1,
      title: "Kanthastitch",
      img:   '/IMG/p6.jpg',
      link:"/dropdown/product-list/Kanthastitch"
    },
    {
      id: 2,
      title: "Pure Silk Replica",
      img:   '/IMG/p3.jpg',
      link:"/dropdown/product-list/Pure Silk Replica"
    },
    {
      id: 3,
      title: "Pure Silk",
      img:  '/IMG/p1.jpg',
      link:"/dropdown/product-list/Pure Silk"
    },
    {
      id: 4,
      title: "Fancy Saree",
      img:   '/IMG/p7.png',
      link:"/dropdown/product-list/Fancy Saree"
    },
    // {
    //     id: 5,
    //     title: "Jamdani",
    //     img:   'https://ik.imagekit.io/jkmpwfudl/Darsh/F.jpg?updatedAt=1731760405716',
    //   },
    //   {
    //     id: 6,
    //     title: "Bengali saree",
    //     img:     'https://ik.imagekit.io/jkmpwfudl/Darsh/DARSH%20WEB%20BANNER.jpg?updatedAt=1731808248359',

    //   },
  ];
 
  const handleNavigation = (link) => {
    window.scrollTo(0, 0); 
    navigate(link);  
  };
  
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>TOP CATEGORIES</h2><br/>
      {/* <p className={styles.subheading}>
        Browse a diverse range of categories, from chic sarees to versatile
        accessories. Elevate your style today!
      </p> */}
      <div className={styles.categories}>
        {categories.map((category) => (
          <div className={styles["category-card"]} key={category.id}
          onClick={() => handleNavigation(category.link)}>
            <div className={styles["category-image"]}>
              <img src={category.img} alt={category.title} />
            </div> 
            <div className={styles["category-title"]}>{category.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopCatagory;
